












































































































































































































import Vue from "vue";
import _ from "lodash";
import store from "../../store/session";
import { ClientOrder } from "../../model/entity/client_order";
import {
  CategoryOption,
  Menu,
  MenuOption,
  MenusCategoryOptions,
  MenusMenuOption,
} from "../../model/menu";

export default Vue.component("SOMenuDetailPopup", {
  components: {
    QTranslate: () => import("../elements/QTranslate.vue"),
    QCheckbox: () => import("../elements/QCheckbox.vue"),
    QSelectbox: () => import("../elements/QSelectbox.vue"),
    QButton: () => import("../elements/QButton.vue"),
    QImage: () => import("../elements/QImage.vue"),

    SOPrice: () => import("../components/SOPrice.vue"),
    QRemainMenuOption: () => import("../elements/QRemainMenuOption.vue"),
  },
  props: {
    parent: {
      type: undefined, // HTMLElement
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },

    allowRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuId: {
      type: String,
      required: true,
    },
    order: {
      type: Object, // ClientOrder
      required: false,
      default: () => {
        return new ClientOrder();
      },
    },
  },
  computed: {
    menu(): Menu {
      const currentMenu = store.state.cacheMenus[this.menuId] || {
        id: null,
        shop_id: null,
        category_id: null,
        name: "",
        description: "",
        is_liquor_tax: false,
        price: 0,
        speed: "",
        image: "",
        menu_options: [],
        formatted_name: "",
        limit_option: false,
        limit_length: null,
        limit_last_time: null,
        limit_start_automatic: false,
        is_set_maximum_order_per_day: false,
        remain_order: null,
        is_soldout: false,
        prices: {
          exclud: 0,
          exclud_in_tax: 0,
          includ: 0,
        },
        category_options: [],
      };
      return currentMenu;
    },
    menuLimitTime: () => (menu: Menu) => {
      return `${menu.limit_length}分 (L.O)${menu.limit_last_time}分前`;
    },
    isValid() {
      if (this.menu.is_soldout) {
        return false;
      }

      if (
        store.state.tableSession.quantities <
          this.menu.minimum_customer_order ||
        (this.menu.is_set_maximum_order_per_day &&
          this.menu.remain_order < this.quantity)
      ) {
        return false;
      }

      const optionIds = this.options || [];
      const summary = _.countBy(this.menu.menu_options, (menu_option) => {
        const allOption = _.map(
          menu_option.menus_menu_options,
          (opt) => opt.id
        );
        const selectCount =
          _.countBy(optionIds, (value) => {
            return _.includes(allOption, value);
          }).true || 0;
        const min = menu_option.min;
        const max = menu_option.max;
        // console.log(min, selectCount, max, min <= selectCount && selectCount <= max);

        return min <= selectCount && selectCount <= max;
      });
      const falseCount = summary.false || 0;

      return falseCount == 0;
    },
    hasCategoryOption: () => (menu: Menu) => {
      return menu.category_options && menu.category_options.length > 0;
    },
    menuCategories: () => (menu: Menu): CategoryOption[] => {
      return menu.category_options || [];
    },
    nameOfCategoryOption: () => (menuCategoryOption: MenusCategoryOptions) => {
      const category = _.find(
        store.state.categories,
        (item) => item.id === menuCategoryOption.category_id
      );

      if (!category) {
        return null;
      }

      return category.name;
    },
    menuNameOfCategoryOption: () => (
      menuCategoryOption: MenusCategoryOptions
    ) => {
      const category = _.find(
        store.state.categories,
        (item) => item.id === menuCategoryOption.category_id
      );
      // join all menu name of this category
      if (!category) {
        return null;
      }

      return _.map(category.menus, (menu) => menu.name).join("・");
    },
    menuOptionList: () => (menus) => {
      return _.map(menus, item => {
        item.disabled = item.menu.is_soldout || (item.menu.is_set_maximum_order_per_day && (item.menu.remain_order < 1));

        return item;
      });
    }
  },
  data() {
    return {
      delay: 200,
      isShow: false,

      options: this.order.options || [],
      quantity: this.order.quantity,
    };
  },
  mounted() {
    if (this.isOpen) {
      this.isShow = false;
      setTimeout(() => {
        this.isShow = true;
      }, this.delay);
    }

    (this.parent as HTMLElement).appendChild(this.$el);
  },
  watch: {
    isShow() {
      store.commit("setIsFixScroll", this.isShow);
    },
  },
  methods: {
    updateQuantity(offset: number) {
      this.quantity = Math.max(1, this.quantity + offset);
    },
    handleAddOption(optionKey: string) {
      const tmp = _.cloneDeep(this.options);
      tmp.push(optionKey);
      this.options = tmp;
    },
    handleRemoveOption(optionKey: string) {
      const tmp = _.cloneDeep(this.options) as string[];
      const targetIndex = _.findIndex(tmp, (item) => item == optionKey);
      if (targetIndex == -1) {
        return;
      }
      tmp.splice(targetIndex, 1);
      this.options = tmp;
    },

    handleClickRemove() {
      this.close();
      this.$emit("remove", {
        menuId: this.menuId,
        options: this.options,
        quantity: this.quantity,
      });
    },
    handleClickAdd() {
      this.close();
      this.$emit("update", {
        menuId: this.menuId,
        options: this.options,
        quantity: this.quantity,
      });
    },

    defaults(menusMenuOptions: MenusMenuOption[]) {
      return _.filter(menusMenuOptions, function(opt) { return (opt.default_option === true && opt.disabled === false); }).map(
        (opt) => opt.id
      );
    },

    close() {
      this.$emit("close");
      this.remove();
    },
    remove() {
      this.isShow = false;

      setTimeout(() => {
        this.$el.remove();
      }, this.delay);
    },
    destroyed() {
      this.$el.remove();
    },
    isDisabled(menu: Menu) {
      if (menu.is_set_maximum_order_per_day && menu.remain_order < 1) {
        return "disabled";
      }

      return false;
    },
  },
});
